import React, { useState } from "react";
import { ParallaxProvider } from "react-scroll-parallax";
import Header from './components/Header';
import Footer from './components/Footer';
import Services from './components/Services';
import AboutUs from './components/AboutUs';
//import Contact from './components/Contact';
import Schedule from "./components/Schedule";
import './App.css';

function App() {

  const [fadeInSection1, setFadeInSection1] = useState(false);
  const [fadeInSection2, setFadeInSection2] = useState(false);
  // const [fadeInSection3, setFadeInSection3] = useState(false);
	const [fadeInSection4, setFadeInSection4] = useState(false);

  return (
    <div className="App">
      <Header />
      <main>
        <ParallaxProvider>
          <Services fadeIn={fadeInSection1} setFadeIn={setFadeInSection1} />
          <AboutUs fadeIn={fadeInSection2} setFadeIn={setFadeInSection2} />
          {/* <Contact fadeIn={fadeInSection3} setFadeIn={setFadeInSection3} /> */}
					</ParallaxProvider>
					<Schedule fadeIn={fadeInSection4} setFadeIn={setFadeInSection4} />
      </main>
      <Footer />
    </div>
  );
}

export default App;
