import React, { useEffect } from "react";
import jsonData from "../data/jsonData";
import { Parallax } from "react-scroll-parallax";
import { useInView } from "react-intersection-observer";
import "./Services.css";

const Services = ({ fadeIn, setFadeIn }) => {
  const [ref, inView] = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      setFadeIn(true);
    }
  }, [inView, setFadeIn]);

  const servicesData = jsonData.data[0].services[0].tiles;

  return (
    <section id="Services" ref={ref} className={`services`}>
      <div className="parallax-image">
        <Parallax y={[-20, 20]} tagouter="div">
          <div className="parallax-image-inner" />
        </Parallax>
      </div>
      <div className={`services-content ${fadeIn ? "fade-in" : ""}`}>
        <>
          <div className="servicesTitle">
            <h2>Services</h2>
          </div>
          <div className="tileSection">
            {servicesData.map((tile, index) => (
              <div key={index} className="tile">
                <h3>{tile.title}</h3>
                <ul className="list">
                  {tile.list.map((item, i) => (
                    <li key={i} className="list-item">
                      {item.text}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </>
      </div>
    </section>
  );
};

export default Services;
