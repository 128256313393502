import React, { useState } from "react";
import logo from "../images/logo.png";
import "./Header.css";

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleClick = () => {
    setMenuOpen(false);
  }

  return (
    <div className="Header">
      <header>
        <div className="header-container">
          <div className="header-left">
            <img src={logo} alt="WWP Logo" />
            <h1>WWP Consulting</h1>
            <button onClick={toggleMenu} className="menu-toggle">
              &#9776;
            </button>
          </div>
          <nav className={`header-right ${menuOpen ? "open" : ""}`}>
            <ul>
              <li>
                <a href="#Services" onClick={handleClick}>Services</a>
              </li>
              <li>
                <a href="#AboutUs" onClick={handleClick}>About Us</a>
              </li>
              <li>
                <a href="#ContactUs" onClick={handleClick}>Contact</a>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    </div>
  );
}

export default Header;
