import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
//import reportWebVitals from './reportWebVitals';

// const loadOnceHubScript = () => {
// 	const script = document.createElement("script");
// 	script.src = "https://cdn.oncehub.com/fo/form.js";
// 	script.id = "fo-index";
// 	script.setAttribute("data-fo-params", "website_id=WEB-2B4793D41D");
// 	document.body.appendChild(script);
// }

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>

);

// Execute the OnceHub Script when the DOM is fully loaded
document.addEventListener("DOMContentLoaded", () => {
	//loadOnceHubScript();
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
