import React, { useCallback, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import ClipLoader from "react-spinners/ClipLoader";
import "./Schedule.css";

const Schedule = ({ fadeIn, setFadeIn }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const [scriptStatus, setScriptStatus] = useState("loading");

  useEffect(() => {
    if (inView) {
      setFadeIn(true);
    }
  }, [inView, setFadeIn]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const mailtoLink = `mailto:info@wwp.com.au?subject=Contact Form Submission&body=Name: ${name}%0D%0AEmail: ${email}%0D%0AMessage: ${message}`;
    window.location.href = mailtoLink;
  };

  const AltContactUs = () => {
    return (
      <form className="contact-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            value={name}
            required
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            required
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Please type your message here"
          />
        </div>
        <div className="form-group">
          <button type="submit">Send Message</button>
        </div>
      </form>
    );
  };

  const appendScript = useCallback(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.oncehub.com/fo/form.js";
    script.id = "fo-index";
    script.setAttribute("data-fo-params", "website_id=WEB-2B4793D41D");

    script.onload = () => {
			console.log("onload event fired")
			setScriptStatus("loaded");
		}
    script.onerror = () => {
			console.log("onload event fired")
			setScriptStatus("error");
		}
    document.body.appendChild(script);

    const timer = setTimeout(() => {
			if (scriptStatus === "loading") setScriptStatus("timeout");
	}, 2000);

    return () => {
      clearTimeout(timer);
			console.log("removeChid occurs")
      //document.body.removeChild(script);
    };
  }, [scriptStatus]);

  useEffect(() => {
    appendScript();
  }, [appendScript]); // Notice the empty array to ensure the script is added once

  return (
    <section id="Schedule" ref={ref} className="schedule">
      <div className={`schedule-content ${fadeIn ? "fade-in" : ""}`}>
        <h2>Contact Us</h2>

        {scriptStatus === "loading" && <ClipLoader color="#000" size={30} />}
        {scriptStatus === "error" && (
					<>
          <p>There was an error loading meeting scheduler. Please try refreshing or
              contact us directly via the following form</p>
					<AltContactUs />
					</>
        )}
        {/* {scriptStatus === "timeout" && (
          <>
            <p>
              Sorry, the meeting scheduler took too long to load. Please try refreshing or
              contact us directly via the following form.
            </p>
            <AltContactUs />
          </>
        )} */}

        <div
          data-fo-form="FORM-AAFDE8AD1D"
          style={{
            width: "100%",
            height: "400px",
            display: scriptStatus === "loaded" ? "block" : "none",
						// display: "block" ,
          }}
        />
      </div>
    </section>
  );
};

export default Schedule;
