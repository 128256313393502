import React, { useEffect } from "react";
import jsonData from "../data/jsonData";
import { Parallax } from "react-scroll-parallax";
import { useInView } from "react-intersection-observer";
import "./AboutUs.css";

const AboutUs = ({ fadeIn, setFadeIn }) => {
  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      setFadeIn(true);
    }
  }, [inView, setFadeIn]);

  const aboutUs = jsonData.data[1].AboutUs;

  return (
    <section id="AboutUs" ref={ref} className={`aboutus`}>
      <div className="parallax-image">
        <Parallax y={[-20, 20]} tagouter="div">
          <div className="parallax-image-inner" />
        </Parallax>
      </div>
      <div className={`aboutus-content ${fadeIn ? "fade-in" : ""}`}>
        <>
          <div className="aboutus-title">
            <h2>About Us</h2>
          </div>
          <div className="">
            {aboutUs.map((section, index) => (
              <div key={index}>
                <p>{section.para}</p>
                {section.list && (
                  <div className="">
                    <ul>
                      {section.list.map((item, i) => (
                        <li key={i}>{item.text}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            ))}
          </div>
        </>
      </div>
    </section>
  );
};

export default AboutUs;
