const jsonData = {
	"data": [
			{
					"services": [
							{
									"tiles": [
											{
													"title": "Business",
													"list": [
															{
																	"text": "Perform independent reviews of ICT environments or call centres and provide recommendations for improvement."
															},
                              {
                                  "text": "Provide strategic insights as a 'CTO for hire' on digital transformation, technical teams, and business system architecture."
                              },
															{
																	"text": "Assist in developing data driven business models and strategies to achieve your goals."
															}
													]
											},
											{
													"title": "Technical",
													"list": [
															{
																	"text": "Design and develop native IOS applications (Swift/Flutter) with backend integrations."
															},
															{
																	"text": "Craft both static and dynamic SPA web applications (ReactJS) for various platforms - on premise or cloud."
															},
															{
																	"text": "Provide IoT and Cloud solutions, including web/API/database integrations on platforms like Azure and AWS."
															},
															{
																	"text": "Design electronic circuits and prototype PCBs, especially for low-power IoT sensor devices."
															},
															{
																	"text": "Offer embedded system design and programming across diverse microcontrollers like Nordic nRF, esp32, STM32, and more."
															}
													]
											},
											{
													"title": "Clients",
													"list": [
															{"text": "Investment / Financial advisors.",},
															{"text": "High-touch customer environments like call centers."},
															{"text": "Local non-profit organisations."},
															{"text": "Government entities (local and state)."},
															{"text": "Educational institutions, including TAFE, schools and universities."},
															{"text": "Agriculture and Industrial sectors."},
															{"text": "Mining and Construction."},
															{"text": "Airports and Utility services."}
													]
											}
									]
							}
					]
			},
			{
					"AboutUs": [
							{
									"para": "Welcome to WWP Consulting, your dependable partner for strategic and technical ITC solutions across South East Queensland. We're dedicated to enhancing business performances by aligning tech teams with your organisational goals, ensuring successful outcomes."
							},
							{
									"para": "Our offerings at WWP Consulting include:",
									"list": [
											{"text": "Guidance on designing scalable software solutions on Cloud platforms like Azure, AWS, GCP, and more."},
											{"text": "Evaluations of current business software and development processes to identify enhancement areas."},
											{"text": "Close collaboration with stakeholders to set clear targets and aspirations."},
											{"text": "Tailored strategies to achieve your goals, emphasising efficiency and reliability."},
											{"text": "Support in project management, risk assessments, and budget planning."}
									]
							},
							{
									"para": "Want to transform your business idea with robust IT and digital strategies? Boasting over 30 years of experience, WWP Consulting aids startups, tech firms, and SMBs in crafting strategies aligned with their core objectives."
							},
							{
									"para": "Our expertise is in nurturing companies to foster a team culture that emphasises continuous improvement and efficient value delivery. Collaborate with WWP Consulting today and embark on a successful business journey."
							}
					]
			}
	]
};

export default jsonData;
