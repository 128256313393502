import React from 'react';
import './Footer.css';

function Footer() {
	return (
		<div className="footer">
        <p>&copy; 2023 World Wide Productions Pty Ltd. ABN: 84 068 751 771</p>
    </div>
	);
}

export default Footer;